<template>
    <grid-view
        v-if="ready"
        :title="title"
        filter
        create
        create-title="Добавить пользователя"
        :url-create="createUrl"
        :url-update="updateUrl"
        store-module="user"
        :extra-filters="extraFilters"
        :model-fields="modelFields"
        :headers="headers"
        :format="formatItems"
    >
        <!-- Filter -->
        <template v-slot:filter="{ filter }">
            <v-row>
                <v-col lg="6" sm="12">
                    <v-select
                        v-model="filter.active"
                        :items="[{
                            text: 'Нет',
                            value: false
                        },{
                            text: 'Да',
                            value: true
                        }]"
                        label="Активность"
                        clearable
                    ></v-select>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.name"
                        autocomplete="new-email"
                        label="ФИО" 
                        type="text" 
                        color="purple darken-2" 
                    ></v-text-field>
                </v-col>

                <v-col v-if="queryRole === 'teacher'" lg="6" sm="12">
                    <v-select
                        v-model="filter.subject_name"
                        :items="filterSubjectValues"
                        clearable
                        label="Предмет"
                    ></v-select>
                </v-col>

                <v-col v-if="['student', 'teacher'].includes(queryRole) " lg="6" sm="12">
                    <v-select
                        v-model="filter.group_name"
                        :items="filterGroupValues"
                        clearable
                        label="Класс"
                    ></v-select>
                </v-col>

                <v-col v-if="queryRole === 'student'" lg="6" sm="12">
                    <v-text-field 
                        v-model="filter.grade"
                        label="Параллель" 
                        type="number" 
                        color="purple darken-2" 
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <!-- END Filter -->
    </grid-view>
</template>
<script>
import DataListMixin from '@/mixins/DataListMixin'
import GridView from '@/components/crud/GridView.vue'

export default {
    name: 'UserIndex',
    components: { GridView }, 
    mixins: [DataListMixin],
    data () {
        return {
            groups: [],
            ready: false
        }
    },
    computed: {
        queryRole () {
            return !this.$route.query.role || this.$route.query.role === 'undefined' ? '' : this.$route.query.role;
        },
        createUrl () {
            const baseUrl = '/user/create'
            return this.queryRole ? `${baseUrl}?role=${this.queryRole}` : baseUrl
        },
        updateUrl () {
            const baseUrl = '/user/update/:id'
            return this.queryRole ? `${baseUrl}?role=${this.queryRole}` : baseUrl
        },
        title () {
            const values = {
                teacher: 'Преподаватели',
                student: 'Учащиеся',
                admins: 'Администраторы',
                default: 'Пользователи'
            };
            return values[this.queryRole] || values.default;
        },
        modelFields () {
            const fields = {
                student: [ 'active', 'name', 'role', 'group_id' ],
                teacher: [ 'active', 'name', 'role', 'group_id', 'subjects' ],
                admins: [ 'active', 'name', 'role' ]
            }
            return fields[this.queryRole]
        },
        extraFilters () {
            const filters = {
                teacher: { role: 'teacher' },
                student: { role: 'student' },
                admins: { role: { in: ['admin_vpr', 'admin_school'] } }
            }
            return filters[this.queryRole] || { role: { '!=': 'admin' }}
        },
        headers () {
            const extraFields = {
                    student: [
                            { text: 'Класс', value: 'names', width: 1 },
                            { text: 'Параллель', value: 'grade', width: 1 }
                        ],
                    teacher: [
                            { text: 'Классы', value: 'names', width: 1 },
                            { text: 'Предметы', value: 'subjects_formatted', width: 1 },
                        ]
                }
            
            return [
                // { text: 'ID', value: 'id', width: 1 },
                // { text: 'Активность', value: 'active', width: 1, sortable: false },
                { text: '', value: 'edit', width: 1, sortable: false },
                { text: 'ФИО', value: 'name', width: 1 },
                { text: 'Роль', value: 'role', width: 1, sortable: false },
                // { text: 'Логин', value: 'login' },
                ...(extraFields[this.queryRole] || []),
                { text: '', value: 'edit', width: 1, sortable: false },
                // { text: '', value: 'remove', width: 1, sortable: false }
            ]
        },
        filterGroupValues () {
            return this.groups.map(g => ({ text: `${g.name} класс`, value: g.name }))
        },
        filterSubjectValues () {
            return this.$store.state.app.subjects
        }
    },
    methods: {
        formatItems(items) {
            return _.map(items, (item) => {
                // Active
                _.set(item, 'active', item.active === true ? 'Да' : 'Нет');
                // Role
                const role_i = _.findIndex(this.$store.state.user.roles, {value: item.role});
                _.set(item, 'role', _.get(this, `$store.state.user.roles.${role_i}.text`, 'Неизвестно'));
                const groups = this.groups.filter(g => item.group_id?.includes?.(g.id))
                
                item.names = groups?.map(g => g.name).join(', ') || []
                item.grade = groups?.[0]?.grade
                item.subjects_formatted = item.subjects?.map(s => s.subject).join(', ')

                return item;
            })
        },
        async fetchGroups () {
            const { success, data, error } = await this.$store.dispatch(`group/list`, {
                pagination: 0,
                fields: ['id', 'name', 'grade'].join(',')
            })
            if (!success)
                throw new Error(error)
            
            this.groups = data?.items
        }
    },
    async created () {
        await this.fetchGroups()
        this.ready = true
    }
}
</script>